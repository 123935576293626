import * as React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router';
import { EventsContainer } from './containers/events';
import { EventDetailsContainer } from './containers/event-details-container';
import './network-events.scss';

const EventsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={[path, `${path}/filter/:filter`]} component={EventsContainer} />
      <Route path={`${path}/:eventId`} component={EventDetailsContainer} />
    </Switch>
  );
};

export default EventsRouter;
